
mat-toolbar {
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);

  &.topbar {
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;
  }

  .navbar-header {
    text-align: center;

    .navbar-brand {
      display: flex;
      line-height: 18px;
      align-items: center;

      img {
        height: 37px;
      }

      b {
        margin-right: 5px;
      }
    }
  }

  .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
}

/*******************
 Topbar
*******************/
.topbar {
  position: relative;
  z-index: 2;
  background: transparent;

  &-options {
    flex-grow: 1;
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 30px;
    @media (max-width: 920px) {
      justify-content: space-between;
      overflow: auto;
      //min-width: 55%;
    }
  }
}

.example-sidenav-container {
  flex: 1;
}

.example-sidenav-container {
  flex: 1 0 auto;
}
