/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*==============================================================
 For Laptop & above all (1650px)
 ============================================================== */

@media (min-width: 1650px) {
  .main-container.boxed {
    max-width: 1920px;
    margin: 0 auto;
  }
}
/*==============================================================
 For Laptop & above all (1370px)
 ============================================================== */

@media (max-width: 1600px) {
  .main-container.boxed {
    max-width: 1300px;
    margin: 0 auto;
  }
}

/*-- ==============================================================
 Small Desktop & above all (1024px)
 ============================================================== */

@media (min-width: 1024px) {
  .card-group {
    padding: 0 15px;
    display: flex;
    flex-flow: row wrap;
    .mat-card {
      margin: 15px 0px;
      flex: 1 0 0%;
      border-left: 1px solid $border;
      &:first-child {
        border: 0px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .card-group {
    margin: 15px 0;
    .mat-card {
      margin: 0 15px;
      border-top: 1px solid $border;
      &:first-child {
        border: 0px;
      }
    }
  }
}

/*-- ==============================================================
 Ipad & above all(768px)
 ============================================================== */

@media (min-width: 768px) {
  .navbar-header {
    width: 174px;
    flex-shrink: 0;
    .navbar-brand {
      padding-top: 0px;
    }
  }
}

/*-- ==============================================================
 Phone and below ipad(767px)
 ============================================================== */

@media (max-width: 767px) {
  .mat-toolbar-single-row {
    height: 84px !important;
    margin: 0 20px;
  }
  .d-flex {
    display: block;
  }
  .no-block {
    display: flex;
  }
}
